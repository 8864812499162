import React from "react";
import { Container, Row, Col } from "reactstrap";
import MyNavbar from "./MyNavbar";
import MyFooter from "./MyFooter";


class BoxIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      webmodels: []
    };
  }

  componentDidMount() {
    let _this = this;

    var webmodels = [];
    for (var i = 1; i < 25; i++) {
      if([12,13,14,16,23].includes(i)){
        continue
      }
      webmodels.push({
        // url: "https://tanyungui.com/web" + i + "/index.html",
        url: "https://becomingrich.cn/web" + i + "/index.html",
        name: "网站模板" + i,
        id:i,
      })
    }

    this.setState({
      webmodels: webmodels
    })
  }

  cardClickFun = (e) => {
    var url = e.currentTarget.dataset["url"]
    window.open(url, '_blank')
  }


  render() {
    const { webmodels } = this.state;
    return (
      <React.Fragment>
        <MyNavbar />
        <main ref="main" className="indexmain">
          <img src="images/portal-frist.png" className="fristPageImage" />
          <div className="fristPageCardBox">
            <Container>
              <div className=" boxIndex">
                <Row>
                  {webmodels ?
                    webmodels.map(item => {
                      return (
                        <Col xs="6" sm="4" className="cardStyle" data-url={item.url} onClick={this.cardClickFun}>
                          <div className="cardStyleDiv">
                            <img src={"images/web" + item.id + ".jpg"} className="cardImageBackgroud" />
                          </div>
                        </Col>

                      )

                    })
                    : null}
                </Row>
              </div>

            </Container>

          </div>
{/* 
          <section className="section section-components">
            <Container>

            </Container>
          </section> */}
        </main>
        <MyFooter />
      </React.Fragment>
    );
  }
}

export default BoxIndex;

