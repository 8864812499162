import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import Index from "./myview/BoxIndex.jsx";
import Calculation from "./myview/Calculation.jsx";
import "./css/design-react.css";
import "./index.css";

ReactDOM.render(
  <HashRouter>
    <Switch>
      <Route path="/" exact render={props => <Index {...props} />} />
      {/* <Route path="/login-page" exact render={props => <Login {...props} />} /> */}
      <Route exact  path="/cal" component={Calculation} />
      <Redirect to="/" />
    </Switch>
  </HashRouter>,
  document.getElementById("root")
);
