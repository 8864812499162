import React from "react";
import { Container, Row, Col } from "reactstrap";

class Calculation extends React.Component {
      constructor(props) {
            super(props);
            this.state = {
                  leftMoney: 0,
                  nowPrice: 0,
                  stockText: ""
            };
      }

      componentDidMount() {

      }

      leftMoneyInputFun = (e) => {
            this.setState({
                  leftMoney: e.currentTarget.value | 0
            }, this.calculationFun)
      }

      nowPriceInputFun = (e) => {
            this.setState({
                  nowPrice: e.currentTarget.value | 0
            }, this.calculationFun)
      }

      calculationFun = () => {
            const { leftMoney, nowPrice } = this.state;
            if (!nowPrice || !leftMoney) { return; }

            console.log(leftMoney, nowPrice)

            //每次只买75%的量,30倍杠杆，30%收益全抛， 10%损失全抛
            var array = Array.from(Array(100), (v, k) => k+1);
            var stockText = <div>
                  <div>{"止盈价：" + nowPrice * 1.01 }</div>
                  <div>{"止损价：" + nowPrice * 0.9973 }</div>
                  {
                        array.map(i => {
                              return <div>{i + " => " + (Math.pow(1.3, i) * (leftMoney * 0.75) + leftMoney * 0.25)}</div>
                        })
                  }
            </div>

            this.setState({
                  stockText: stockText
            })
      }

      render() {
            const { stockText } = this.state;
            return (
                  <React.Fragment>
                        <Container>
                              <Row>
                                    <Col xs="6" sm="4"  >
                                          <div className="cardStyleDiv">
                                                <input onChange={this.leftMoneyInputFun} type="number" className="cardInput" placeholder="请输入余额" />
                                                <input onChange={this.nowPriceInputFun} type="number" className="cardInput" placeholder="请输入现价" />
                                                <div className="stockText">{stockText}</div>
                                          </div>
                                    </Col>
                              </Row>

                        </Container>
                  </React.Fragment>
            );
      }
}

export default Calculation;

