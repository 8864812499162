import React from "react";
import { Link } from "react-router-dom";

class MyFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: null
    };
  }

  componentDidMount() {
    let _this = this;

  }


  render() {
    const { state } = this.props;
    return (
      <React.Fragment>
        <div className="footerBox" align="center">
          <img class="footerImg" src="images/gxb.png" alt=""></img>
          <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" className="gongxinbu" target="_blank">桂ICP备20005165号-2</a>
          {'Copyright © '}
          <a href="#">AI必盈</a>
          {' '}
          {new Date().getFullYear()}
          {'.'}
        </div>
      </React.Fragment>
    );
  }
}

export default MyFooter;

